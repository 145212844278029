import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';



export default function Privacy() {
	const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [boxHeight, setBoxHeight] = useState('400px');

	useEffect(() => {
        // Get the device's screen width and height
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
    
        // If the screen width or height is less than a certain value, assume the user is on a mobile device
        if (screenWidth < 768 || screenHeight < 768) {
          setBoxHeight('200px');
        }
      }, []);


    const handleClickOpen = () => {
    setOpen(true);
    };
    const handleClose = () => {
    setOpen(false);
    };
      
	return (
		<Box sx={{
			height: boxHeight,
			overflow: 'scroll',
			overflowX: 'hidden',
		}}>
			<Typography component='h1' variant='h1' align='center' gutterBottom>
				{t('common.privacy')}
			</Typography>
			{i18n.language == "en"
			?
				(<p>
                    Last update February 6,2023<br/><br/>
					<b>1. Information we collect</b><br/><br/>
					When using the services provided by our company, we will comply with applicable laws and regulations and collect personal information and personal content necessary for providing services from users. The Service is intended for persons over the age of 16.<br/><br/>

                    When collecting personal information from users, we will clearly indicate the purpose 
                    of use.<br/><br/>

                    When using personal information, it will be done within the scope of the "purpose of 
                    use" that has been clearly indicated to the user in advance, and if it becomes 
                    necessary to change the "purpose of use", that effect will be clearly indicated.<br/><br/>

					<b>2. Purpose of use of personal information</b><br/><br/>
					We use personal information for the purpose of providing, developing, and improving 
                    our services.<br/><br/>

                    Personal information will be used within the scope of the purpose of collection, managed appropriately, and will not be disclosed or provided to third parties without the consent of the user, except in special circumstances.<br/><br/>

                    In order for the information collected by this service to be useful to society, information that identifies individuals such as names is deleted, and after considering the impact on individual privacy, anonymously processed information is created, and third parties such as related research organizations are created may be provided to the person.<br/><br/>

                    We will clearly indicate that the information is anonymously processed information to the third party to whom it is provided.<br/><br/>

					<b>3. Protection of personal information</b><br/><br/>
					We will keep personal information accurate and up-to-date, and take measures to prevent unauthorized access, loss, destruction, falsification and leakage of personal information.<br/><br/>

                    Based on the laws and regulations applicable to the personal information held by our company, we will establish rules for the handling of personal information, develop an organizational system, and strive to appropriately protect personal information.
                    Personal information on this service is structured so that only authorized personnel can access it. In addition, we will prepare an internal manual on the protection of personal information and ensure that officers and employees are thoroughly informed about the protection and management of personal information.<br/><br/>

                    When creating, using and providing anonymously processed information, we will take appropriate security control measures based on the personal information protection management system.<br/><br/>

					<b>4. Cookies and Tracking Technologies</b><br/><br/>
					A cookie (a function that automatically collects connection history information) is information sent from the server to the user's browser and stored on the computer used by the user.<br/><br/>

                    If the user's browser is set to allow cookies, our Web site can send cookies to the user's browser, but to protect privacy, we only allow access to cookies sent by our Web site's server itself.<br/><br/>

                    We do not collect, provide to third parties, or misuse any personally identifiable information about an individual user's online activities, although we may use information obtained through the use of cookies when the user uses our Web site or services for confirmation purposes on certain services.<br/><br/>

                    A tracking ID (tracking identifier)/SDK (software development kit) is information that identifies a smartphone device pre-installed in a smartphone application.<br/><br/>


                    Even if users allow us to track your activity across other companies' apps and websites, in order to protect your privacy, we will collect the information obtained using the tracking ID/SDK from our service's server within the application. Although it may be used for confirmation in our services, such as monitoring user activity, we will never provide it to a third party or use it illegally.<br/><br/>
					
                    <b>5. Changes to Privacy Policy and Purpose of Use</b><br/><br/>
					This privacy policy is subject to change without notice. Any changes will be posted here, so please check back frequently when using the Service.<br/><br/>

                    In the event that personal information is to be used beyond the scope of the clearly stated "purpose of use", we will notify the user in advance of the changed purpose of use, or notify the user in advance to that effect, the content of the change or addition, and the time when it will take effect. It shall be made known by using the Internet or other appropriate methods.<br/><br/>	
					
                    <b>6. Confirmation, change, etc. of personal information and contact information</b><br/><br/>
					When we receive a request for confirmation, correction, disclosure, notification of purpose of use, etc. from a user who has provided personal information, we will promptly respond after confirming the identity of the user.<br/><br/>

                    If you wish to confirm, correct, disclose, or notify us of the purpose of use of your 
                    personal information, or if you have any questions about our privacy policy, please 
                    contact us at the following.<br/><br/>

                    ad- dice Co., Ltd. Personal information protection manager<br/><br/>
                    e-mail: info@ad-dice.com<br/><br/>

                    *You can also send it from the inquiry form.<br/><br/>
                    <a href="https://ad-dice.com/contact-en/">https://ad-dice.com/contact-en/</a><br/><br/>

				</p>)
			:
				(<p>
					<br/>
					<b>１. 個人情報の取り扱いについて</b><br/><br/>
					当社では個人情報保護法を遵守し、購読者や顧客の皆様から個人情報をお聞きする際に「利用目的」を明示します。さらに利用にあたっては皆様に明示した「利用目的」の範囲内にとどめ、「利用目的」の変更が必要とされる場合も、その旨を皆様に明示します。皆様からご提供いただいた個人情報は、当社ならびに当社の関連会社が共同で細心の注意を払って管理します。<br/><br/>

					<b>2. (個人情報の利用について)</b><br/><br/>
					当社では、各種商品・サービスの提供・改善や新商品・サービスの開発などを目的に個人<br/><br/>

                    特定の個人を識別することができる情報(以下「個人情報」といいます)の取り扱いについて規定を定め、また、組織体制を整備し、個人情報の適切な保護に努めております。
                    ご本人から個人情報を収集させていただく場合は、収集目的、ご本人に対する弊社の窓口をお知らせした上で、必要な範囲で個人情報を収集させていただきます。
                    個人情報を収集目的の範囲内で利用するとともに、適切な方法で管理し、特段の事情がない限り、お客様の承諾なく第三者に開示・提供することはありません。
                    個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破壊、改ざん及び漏えい等を防止するための措置を講じております。
                    弊社が、個人情報の処理を外部へ委託する場合には、漏えいや再提供を行わないよう契約により義務づけ、適切な管理を実施させていただきます。
                    個人情報の確認、訂正等を希望される場合には、弊社各窓口までご連絡いただければ、合理的な範囲ですみやかに対応させていただきます。
                    弊社が保有する個人情報に関して適用される法令、規範を遵守するとともに、上記各項における取り組み及び保護活動を、維持、改善してまいります。
                    情報を利用しています。例えば下記のような場合があります。<br/><br/>

					<b>3. 責任の範囲について</b><br/><br/>
					当社の専用ホームページとリンクしている第三者のリンク先や、広告主のサイトで行われている個人情報の収集・利用・取り扱いについては一切責任を負いません。<br/><br/>

					<b>4. Cookieについて</b><br/><br/>
					クッキー（Cookie：接続履歴情報自動収集機能）とは、サーバから利用者のブラウザに送信され、利用者が使用しているコンピューターに蓄積される情報です。<br/><br/>

                    利用者がブラウザの設定でクッキーの送受信を許可している場合、各ウェブサイトはそれぞれのクッキーを利用者のブラウザに送信することができますが、プライバシー保護のため、そのウェブサイトのサーバ自身が送信したクッキーへのみアクセスを許可します。<br/><br/>

                    当社ではクッキーを利用し入手した情報について特定のサービスにおける確認等で使用することはありますが、不正に使用することは一切ありません。<br/><br/>

					
                    <b>5. 利用目的の変更等及び利用停止の手続きについて</b><br/><br/>
					取得時に明示した「利用目的」の範囲を超えて個人情報を利用する場合は、予め変更後の利用目的を皆様に通知もしくは公表しなければなりません。当社ではプライバシー・ポリシー（個人情報保護方針）とリンクしている『個人情報の利用目的変更等に関するお知らせ』のページで、変更後の利用目的を公表するとともに、個人情報の利用停止の手続きも行います。<br/><br/>	
					
                    <b>6. 匿名加工情報</b><br/><br/>
					特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報（「匿名加工情報」）およびその加工方法等に関する情報（「加工方法等情報」）の適正な取扱いの確保について組織として取り組みます。<br/><br/>

                    当社のCOVID-19-ResQプロジェクトにおける匿名加工情報の取扱いについては <Link style={{ cursor: 'pointer' }} onClick={handleClickOpen}>こちら</Link>をご参照ください。<br/><br/>

                    <b>7. 訂正・開示等の手続きについて</b><br/><br/>
					個人情報を提供していただいた皆様から訂正・開示や利用目的通知のお申し出があった場合は、本人確認などの手続きを経て、速やかに対応します。訂正・開示や利用目的通知を希望される場合は、以下までご連絡ください。<br/><br/>

                    株式会社アドダイス<br/><br/>
                    担当：個人情報保護管理者<br/>
                    電話番号： 03-503-6796-7788(受付時間：平日10：00～17：00）<br/>
                    e-mail:info@ad-dice.com<br/>
                    ※お問合せフォームからもお送りいただけます。<br/><br/>

                    <b>8. 個人情報の管理体制について</b><br/><br/>
					当社では個人情報保護に関して、社内マニュアルを策定し、全役員・従業員に保護管理を周知徹底させています。個人情報は権限を付与された担当者しかアクセスできない仕組みになっています。また関連会社や外注先も含めて適切に監督、管理しています。<br/><br/>

                    当社は、個人情報の適正な取り扱いと保護の信頼性向上のため経済産業省および総務省の認定を受けた認定個人情報保護団体である一般財団法人日本情報経済社会推進協会の対象事業者となっております。当社の対応に対して疑問やご不満等があり、解決を必要とされる場合、或いは当社の取扱いかどうか不明な場合等、下記までお申し出下さい。<br/><br/>

                    お問合せ窓口<br/>
                    認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会<br/>
                    苦情の解決の申出先：個人情報保護苦情相談室<br/>

                    住所： 〒106-0032 東京都港区六本木一丁目９番９号六本木ファーストビル内<br/>
                    電話番号： 03-5860-7565　または　0120-700-779<br/><br/><br/>
                    <Typography align="right">
                株式会社アドダイス<br/>
                代表取締役　伊東 大輔<br/>
                制定日　2009年11月1日<br/>
                最終改定日　2021年2月1日<br/>
            </Typography>
                    

				</p>
                )
			}
            <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}> 
        匿名加工情報の取扱いに関する公表事項
        </DialogTitle>
        <DialogContent dividers>
        <Box sx={{
			height: '400px',
			overflow: 'scroll',
			overflowX: 'hidden',
		}}>
        <p>
        株式会社アドダイス（以下当社）のCOVID-19-ResQプロジェクトは、新型コロナウイルス（COVID-19）に対し、「AIでセンサーデータを解析する弊社が培ってきた最先端の情報テクノロジーで、ひとりでも多くの方のいのちを救う」という社会的使命を抱き、賛同する有志らと共にスタートしました。<br/>
当社は、本プロジェクトで収集した情報を社会に役立てるために、匿名加工情報を作成し、関係機関に提供しています。<br/><br/>

<b>１．匿名加工情報とは</b><br/>
匿名加工情報とは、個人情報保護法第2条第6項に定める、特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたものを言います。当社は、氏名等の本人が特定される情報を削除し、本人へのプライバシーへの影響に配慮した上で、匿名加工情報を作成します。<br/><br/>



<b>２．匿名加工情報の第三者提供</b><br/>
COVID-19-ResQプロジェクトを通じて収集した個人情報を匿名加工した上で提供先となる第三者に対して匿名加工情報であることを明示します。<br/><br/>



<b>３．匿名加工情報に含まれる項目</b><br/>

・お客様の属性情報（年齢（年代）　サービス入会暦（入会年月））<br/>
・お客様へのサービス提供にて取得した測定値（皮膚温、血中酸素濃度、心拍数、呼吸数、心位、BP、歩数、睡眠時間等）<br/><br/>



<b>４．匿名加工情報への安全管理措置</b><br/>
当社は、プライバシーマークを取得しています。匿名加工情報の作成、利用、提供にあたっては、個人情報保護マネジメントシステムに基づいて、以下のような形で適切な安全管理措置等を講じています。<br/><br/>


匿名加工情報の作成にあたっては、特定の個人を識別すること 及びその作成に用いる個人情報を復元することができないようにするため、<br/>
　・特定の個人を識別することができる記述等の全部又は一部を削除します。<br/>
　・個人情報に含まれる個人識別符号の全部を削除します。<br/>
　・特異な記述等を削除します。<br/><br/>


<b>5.改訂について</b><br/>
当社では、個人情報の取り扱いに関して適宜見直しを行います。これに伴って本公表事項の内容が予告無く変更されることがありますので予めご了承ください。


				</p>
                </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('form.close')}
          </Button>
        </DialogActions>
      </Dialog>
		</Box>
	)
}