import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';
import LoadingButton from '@material-ui/lab/LoadingButton';
import api from 'src/utilities/axios';

import { 
	Divider,
	Dialog, 
	DialogActions, 
	DialogContent, 
	DialogTitle, 
	TextField, 
	Button,
	Alert,
	Snackbar,
	Grid,
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Typography,
	Link,
	Box,
	Checkbox
} from '@material-ui/core';

import AddSharpIcon from '@material-ui/icons/AddSharp';
import CloseIcon from '@material-ui/icons/Close';

import MarkEmailReadIcon from '@material-ui/icons/MarkEmailRead';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import WarningAmberIcon from '@material-ui/icons/WarningAmber';
import CheckIcon from '@material-ui/icons/Check';
import UploadFileIcon  from '@material-ui/icons/UploadFile';
import encoding from 'encoding-japanese';
import { CSVLink } from 'react-csv';
import FormControl from '@material-ui/core/FormControl';
import Select ,{ SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


interface IAddFormDialogProps {
	updateRender: any
}

export default function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	const [rows, setRows] = React.useState<any[]>([]);
	const [csvRows, setCsvRows] = React.useState<any[]>([]);
	const [openAlert, setOpenAlert] = React.useState<boolean>(false);
	const [openNoUserAlert, setOpenNoUserAlert] = React.useState<boolean>(false);
	const [open, setOpen] = React.useState<boolean>(false);
	const [openHelp, setOpenHelp] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [csvLoading, setCsvLoading] = React.useState<boolean>(false);
	// const [csvUploaded, setCsvUploaded] = React.useState<boolean>(false);
	const [submitted, setSubmitted] = React.useState<boolean>(false);
	const [checked, setChecked] = React.useState(false);
    const hiddenFileInput = React.useRef(null);
	const samplerows = `お名前,Eメールアドレス`;
	const csvLink: any = React.useRef();

	const [lang, setLang] = React.useState('jp');

	const handleLangChange = (event: SelectChangeEvent) => {
		setLang(event.target.value);
	};	

        
    const handleUpload = event => {
		event.preventDefault();
		// event.persist();
		event.stopPropagation();
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
		event.preventDefault();
		// event.persist();
		event.stopPropagation();
		const reader = new FileReader();
		// const csv_data = [];
		try {
			reader.onload = function(e) {
				// const text = e.target.result as string;
				var codes = new Uint8Array(e.target.result as ArrayBuffer);
				const encodingType = encoding.detect(codes);
				var text = encoding.convert(codes, {
					to: 'unicode',
					from: encodingType,
					type: 'string'
				  });
				if(text.length){
					const csv_rows = text.trim().split(/\r?\n/);
					const csv_data = [];
					// if (csv_data.length){
					try{
						csv_rows.forEach(function (item, index) {
							if (index!==0){
								csv_data.push({"name": item.split(',')[0].trim(), "email": item.split(',')[1].trim()});}
						});}catch(e){
							console.log(e);
							setOpenAlert(true);
						}
					

					setCsvRows(csv_data);}
				
			}

			reader.readAsArrayBuffer(event.target.files[0]);
			(document.getElementById("user_form") as HTMLFormElement).reset();		
			// setCsvUploaded(true);
		
		}
		catch(e){
			console.log(e);
		}
		
    };

	const handleAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	React.useEffect(handleAddCsv, [csvRows]);

	function handleAddCsv(): void {
		
		if (csvRows !== undefined && csvRows.length > 0){
			setCsvLoading(true);
			api({
				pathname: '/staff/' + cookie.get('workspace') + '/users/validate',
				method: 'POST',
				data: csvRows,
				handleResponse: (response: any): void => {
					response.data.forEach(function (item) {
						if (item.name.length<1){
							setRows(currentRows=> [...currentRows, {"name":item.name, "email":item.email,"language":item.language, "status":"invalid_name"}]);
						}
						else{
							setRows(currentRows=> [...currentRows, {"name":item.name, "email":item.email,"language":item.language, "status":item.status}]);}
							
					})
					setCsvLoading(false);
					setOpenHelp(false);
				},
				handleError: (): void => {
					setCsvLoading(false);
					setOpenHelp(false);
				}
			})
			
			
		}
		else{
			setOpenHelp(false);
		}
	}

	function handleSubmit(event: React.SyntheticEvent): void {
		
		event.preventDefault();
		event.persist();
		if (rows.filter(i => i.status == "ok").length){
			setLoading(true);

			api({
				pathname: '/staff/' + cookie.get('workspace') + '/users/register',
				method: 'POST',
				data: rows,
				handleResponse: (response: any): void => {

					setRows(response.data);
					setSubmitted(true);
					// response.data.forEach(function (item) {
					// 	setRows({"name":item.name, "email":item.email, "status":item.status});
						
					// })
					setLoading(false);
					// handleClose();
				},
				handleError: (): void => {
					setLoading(false);
				}
			})
			
		}
			
		else{
			setOpenNoUserAlert(true);
		}
	}

	
	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleOpenHelp(): void {
		setOpenHelp(true);
	};

	function handleCloseHelp(): void {
		setOpenHelp(false);
	};

	function handleClose(): void {
		setOpen(false);
	};

	function handleReset(): void {

		setRows([]);
		setCsvRows([]);
		setChecked(false);
		// setCsvUploaded(false);
		(document.getElementById("user_form") as HTMLFormElement).reset();


	};

	function handleFinalClose(): void {
		setOpen(false);
		updateRender();
		
	};

	function closeAlert(): void {
		setOpenAlert(false);
	};

	function closeNoUserAlert(): void {
		setOpenNoUserAlert(false);
	};


	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();
		
		const target = event.target as typeof event.target & {
			email: { value: string },
			name: { value: string },
			language: { value: string },
		}
		
		const found_email = rows.some(el => el.email === target.email.value);
		if (found_email){
			setRows(currentRows=> [...currentRows, {"name":target.name.value, "email":target.email.value,"language":target.language.value, "status":"duplicate_email"}]);
		}
		// else if (target.name.value.length<=1){
		// 	setRows(currentRows=> [...currentRows, {"name":target.name.value, "email":target.email.value, "status":"invalid_name"}]);
		// }
		else{
			api({
				pathname: '/staff/' + cookie.get('workspace') + '/users/validate',
				method: 'POST',
				data: [{
					name: target.name.value,
					email: target.email.value,
					language: target.language.value,
				}],
				handleResponse: (response: any): void => {
					setRows(currentRows=> [...currentRows, {"name":response.data[0].name, "email":response.data[0].email, "language":response.data[0].language, "status":response.data[0].status}])
				},
				handleError: (): void => {
					setLoading(false);
				}
			})

		}

		

	};
	

	const deleteRow = (i:number) => (event) => {
		event.stopPropagation();
		setRows(rows.filter((_, index) => index !== i));
		
   }

   const handleRowLanguageChange = (e, index) => {

	const newRows = rows
	newRows[index].language = e.target.value 
	setRows(newRows);
	setRows(currentRows=> [...currentRows]);
  };
   
   function renderStatus(status) {
	   if (status==="already_exists") {
		   return (<Chip icon={<WarningAmberIcon/>} label={t('form.email_already_exists')} size="small" color="error" variant="outlined" />)
	   } else if (status==="ok") {
		   return (<Chip icon={<CheckIcon/>} label={t('form.satisfactory')} size="small" color="warning" variant="outlined" />)
	   } else if (status==="invalid_email") {
			return (<Chip icon={<WarningAmberIcon/>} label={t('form.invalid_email')} size="small" color="error" variant="outlined" />)
	   } else if (status==="invalid_name") {
		return (<Chip icon={<WarningAmberIcon/>} label={t('form.invalid_name')} size="small" color="error" variant="outlined" />) 
	   } else if (status==="sent") {
		   return (<Chip icon={<MarkEmailReadIcon/>} label={t('form.email_sent')} size="small" color="success" variant="outlined" />)
	   } else if (status==="create_user_error") {
		   return (<Chip icon={<WarningAmberIcon/>} label={t('form.create_user_error')} size="small" color="error" variant="outlined" />)
	   } else if (status==="duplicate_email") {
		return (<Chip icon={<WarningAmberIcon/>} label={t('form.duplicate_email')} size="small" color="error" variant="outlined" />)
	   }
   }	
	  


	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.add_user')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
				
				<DialogTitle>{t('form.add_user')}</DialogTitle>
				<DialogContent> 
				
					<TableContainer>
					<Table sx={{ minWidth: 150 }} size="small" aria-label="a dense table">
						{rows.length? (<TableHead>
							<TableRow>
								<TableCell>{t('form.name')}</TableCell>
								<TableCell>{t('form.email')}</TableCell>
								<TableCell>{t('table.language')}</TableCell>
								<TableCell align='right'>{t('table.status')}</TableCell>
								<TableCell align='right'></TableCell>
							</TableRow>
						</TableHead>):(<TableHead></TableHead>)}
						
						<TableBody>
						{rows.map((row, index:number) => (
							<TableRow
							key={index}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell>{row.email}</TableCell>
							<TableCell>
							<FormControl sx={{ minWidth: 60 }} variant="standard">
													{/* <InputLabel id="demo-simple-select-autowidth-label">{t('table.language')}</InputLabel> */}
													<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={row.language}
													// sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
													label="Language"
													disableUnderline
													
													onChange={e => handleRowLanguageChange(e, index)}
													>
														<MenuItem
														key={'jp'}
														value={'jp'}
														>
														日本語
														</MenuItem>
														<MenuItem
														key={'en'}
														value={'en'}
														>
														English
														</MenuItem>
													</Select>
												</FormControl>
									</TableCell>
							<TableCell align='right'>{renderStatus(row.status)}</TableCell>
							
							<TableCell align='right'>
								<IconButton onClick={deleteRow(index)} disabled={submitted}>
									<DeleteSharpIcon/>
								</IconButton>
								{/* <Button onClick={deleteRow(row.email)} disabled={submitted}>
									<DeleteSharpIcon/>
								</Button> */}
							</TableCell>
							
							</TableRow>
						))}
						</TableBody>
					</Table>
					</TableContainer>
				</DialogContent>
				{submitted? (<DialogActions>
					<Alert variant="outlined"  sx={{ width: '100%' }} severity="success" action={<Button color='primary' variant='outlined' size="small" onClick={handleFinalClose}>{t('form.close')}</Button>}>
						{rows.filter(i => i.status == "sent").length}{t('form.users_created')}
					</Alert>
					
					</DialogActions>):(<div>
				<form onSubmit={handleAdd} id="user_form">
				<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<TextField
									label={t('form.name')}
									id='name'
									name='name'
									variant='outlined'
									size="small"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									label={t('form.email')}
									id='email'
									name='email'
									type='email'
									variant='outlined'
									size="small"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={1}>
							{/* <Tooltip title={t('table.language')}> */}
							<FormControl size='small'>
								<InputLabel id="demo-simple-select-autowidth-label">{t('table.language')}</InputLabel>
								<Select
								labelId="demo-simple-select-autowidth-label"
								id='language'
								name='language'
								value={lang}
								label={t('table.language')}
								// sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
								disableUnderline
								onChange={handleLangChange}
								>
									<MenuItem
									key={'jp'}
									value={'jp'}
									>
									日本語
									</MenuItem>
									<MenuItem
									key={'en'}
									value={'en'}
									>
									English
									</MenuItem>
								</Select>
							</FormControl>
							{/* </Tooltip> */}
							</Grid>
							<Grid item xs={2} style={{ display: "flex", alignItems: 'center' }}>
								<Button variant='outlined' color='primary' type='submit' size='small'>
									{t('form.add')}
								</Button>
							</Grid>
							<Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
								<input
									type='file'
									accept='.csv'
									ref={hiddenFileInput}
									onChange={handleChange}
									style={{display: 'none'}} 
								>
								</input>
								
								<Button color='primary' onClick={handleOpenHelp} size='small'>
									<UploadFileIcon /> {t('form.upload_csv')} 
								</Button>
							</Grid>
							
							
						</Grid>
					</DialogActions>

					<Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert}>
						<Alert severity="error"
						action={
							<IconButton
							aria-label={t('form.close')}
							color="inherit"
							size="small"
							onClick={() => {
								setOpenAlert(false);
							}}
							>
							<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
						>
							{t('form.invalid_csv')}
						</Alert>
					</Snackbar>
					<Snackbar open={openNoUserAlert} autoHideDuration={6000} onClose={closeNoUserAlert}>
						<Alert severity="error"
						action={
							<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpenNoUserAlert(false);
							}}
							>
							<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
						>
							{t('form.no_user_alert')}
						</Alert>
					</Snackbar>
				</form>
				<Divider sx={{ my: 1 }} />
				
				<DialogActions>
					{/* <Tooltip title={t('tooltip.staff.staffs_add_modal')} arrow placement='left'>
						<Button sx={{color: 'text.secondary'}}>
							<HelpOutlineIcon></HelpOutlineIcon>
						</Button>
					</Tooltip> */}
					<Checkbox
						checked={checked}
						onChange={handleAgree}
						size="small"
						// disabled={!rows.length}
						inputProps={{ 'aria-label': 'controlled' }}
						/>
					<Typography  sx={{ fontWeight: 'light' }} variant="body2" component="div">{t('form.terms1')}<Link href={"https://ad-dice.com/resq-ai-support/terms/"} underline="hover" target="_blank" rel="noopener">{t('form.terms2')}</Link>{t('form.terms3')}</Typography>
					<div style={{flex: '1 0 0'}} /> 
					<Button color="primary" onClick={handleReset}>
							{t('table.reset')}
					</Button>
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<LoadingButton
							component='button'
							type='submit'
							
							variant='contained'
							color='primary'
							loading={loading}
							disabled={!checked}
							onClick={handleSubmit}
						>
							{t('form.register')}
					</LoadingButton>
					{/* <Button
						onClick={handleSubmit}
						color='primary'
					>
						{t('form.add')}
					</Button> */}
				</DialogActions></div>)}
				
			
			</Dialog>
			<Dialog
				open={openHelp}
				onClose={handleCloseHelp}
     			maxWidth="md"
				>
				<DialogTitle>
				{t('form.csv1')}
				</DialogTitle>
				<DialogContent>
					<Typography variant="subtitle1" component="div">
					{t('form.csv2')}<Link onClick={()=> csvLink.current.link.click()} component="button" variant="subtitle1">{t('form.csv3')}</Link>{t('form.csv4')}

						
						<Typography variant="subtitle1" component="div">
						<Box sx={{ fontWeight: 'bold', m: 1 }}>{t('form.csv5')}</Box>
						</Typography>
						<Typography variant="subtitle1" component="div">
						{t('form.csv6')}
						</Typography>
						<Typography variant="subtitle1" component="div">
						{t('form.csv7')}</Typography>
						<Typography variant="subtitle1" component="div">
						{t('form.csv8')}</Typography>

					</Typography>					
				</DialogContent>
				<DialogActions>
				<CSVLink
						filename={"sample"}
						data={samplerows}
						enclosingCharacter={``}
						ref={csvLink}
					>
				</CSVLink>
				</DialogActions>
				<DialogActions>
				<Button autoFocus onClick={handleCloseHelp}>
					{t('form.cancel')}
				</Button>
				<LoadingButton
					onClick={handleUpload}
					loading={csvLoading}
					color='primary'
					variant='contained'
					component="span"
					// disabled={csvUploaded}
					size='small'
				>
					{t('form.choose_file')}
				</LoadingButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}