import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Alert,
  Breadcrumbs,
  Divider,
  Link,
  Typography
} from '@material-ui/core'
import Loader from 'src/components/loader';
import api from 'src/utilities/axios';
import { UserReportStateType } from "src/redux/user-report/reducers";
import ResQBand from './resq-band';

export interface IReduxStateProps {
  reduxState: {
    userReport: UserReportStateType;
  };
}

export interface IReduxDispatchProps {
  setStartDate: (_: any) => void;
  setInterval: (_: any) => void;
}

export type Props = IReduxStateProps & IReduxDispatchProps

const TODAY = new Date();
const WEEK_AGO = moment(TODAY).subtract(6, 'days').toDate();

export function ReportsComponent(props: Props) {
	const { t } = useTranslation();
	
	const [data, setData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	function fetchData(): void {
		setLoading(true);
		setMessage(null);
		let pathname = '/user/reports/resq_band';
		pathname += '?timezone=' + timeZone;
		api({
			pathname: pathname,
			method: 'GET',
			handleResponse: (response: any) => {
				setData(response.data.report_list);
				setLoading(false);
			},
			handleError: (error: any) => {
				setLoading(false);
				setMessage(<Alert severity='error' sx={{ mb: 3 }}>{error.response.data.detail}</Alert>);
			}
		})
	}

  function resetReduxData(): void {
    props.setStartDate(WEEK_AGO);
    props.setInterval("weekly");
  }

	React.useEffect(fetchData, []);
  	React.useEffect(resetReduxData, []);

	return (
		<Loader state={loading}>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.reports')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.reports')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			{message}

			<ResQBand data={data} />
		</Loader>
	)
}