import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';
import React from 'react';
import api from 'src/utilities/axios';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import moment from "moment";

import LoadingButton from '@material-ui/lab/LoadingButton';
import DownloadSharpIcon from '@material-ui/icons/DownloadSharp';
import BorderColor from '@material-ui/icons/BorderColor';
import CheckIcon from '@material-ui/icons/Check';

import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteFormDialog from './delete-data-dialog';

// Custom Component import
import LockSwitch from './components/lock-switch';
import FormControl from '@material-ui/core/FormControl';
import Select ,{ SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { CSVLink } from 'react-csv';


function Manage(props: any) {
	const { data } = props;
	const { t } = useTranslation();
	const [editText, setEditText] = React.useState<boolean>(true);
	const [description, setDescription] = React.useState<string>(data.description);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>(data.name);
	const [isPublic, setIsPublic] = React.useState<boolean>(data.is_public);
	const [isAllowed, setIsAllowed] = React.useState<boolean>(data.notification_setting_status === "unlocked" ? true : false);
	const [render, setRender] = React.useState<boolean>(false);
	const [language, setLanguage] = React.useState<string>(data.language);
	const [startDate, setStartDate] = React.useState<string>();
	const [endDate, setEndDate] = React.useState<string>();
	const [notifType, setNotifType] = React.useState<string>('1');
	const [csvLoading, setCsvLoading] = React.useState<boolean>(false);
	const [filename, setFilename] = React.useState<string>();
	const [csvData, setCsvData] = React.useState<any>([]);


    const csvLink: any = React.useRef();
    

	const languages: any = ['jp', 'en']

	const updateRender = (): void => setRender(!render);

	function PublicRender(): JSX.Element {

		if (isPublic) {

			return (
				<TableCell align='left'><Chip label={t('table.public')} color="info" /></TableCell>

			)
		} else {

			return (

				<TableCell align='left'><Chip label={t('table.private')} /></TableCell>

			)
		}
	}

	const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAllowed(e.target.checked);
    }

	const handleLanguageChange = (event: SelectChangeEvent) => {
		setLanguage(event.target.value);
	};

	const changeValue = (e: any) => {
		if (e.target.id === "name") {
			setName(e.target.value);
		} else if (e.target.id === "description") {
			setDescription(e.target.value);
		} else {
			isPublic ? setIsPublic(false) : setIsPublic(true);
		}
	}


	function handleEdit(e:any) {
		if (!loading) {
			if (editText && e) {
				setEditText(false);
			} else {
				setEditText(true);
			}
		} else return null;

	}
	const handleChange = (event: React.SyntheticEvent) => {
		setLoading(true);
		event.preventDefault();
		event.persist();


		api({
			pathname: '/entities/' + data.workspace_name,
			method: 'PUT',
			data: {
				name: name,
				description: description,
				is_public: isPublic,
				notification_setting_status: isAllowed ? "unlocked" : "locked",
				language:language
			},
			handleResponse: (): void => {

				setLoading(false);
				setEditText(true);

			},
			handleError: (): void => {
				setLoading(false);
			}

		})


	};

	function handleSubmit(event: any) {
		setCsvLoading(true);
		event.preventDefault();

		let startUnixDate = Date.parse(startDate);
		let endUnixDate = Date.parse(endDate);		

		let pathname = '/admin/' +  data.workspace_name + '/notifications'
		pathname += '?start_time=' + startUnixDate / 1000;
		pathname += '&end_time=' + endUnixDate / 1000;
		pathname += '&notif_type=' + notifType;
		

		api({
			pathname: pathname,
			method: 'GET',
			handleResponse: (response: any): void => {
				let csv_data = [];
				if (notifType == "1"){
					response.data.map((notification) => {
                        const formattedDetail = `${notification.report.name_jp}の値が${notification.value}${notification.report.unit}です。貴方が設定された${notification.scope}レッドゾーンになっています。`;
                        csv_data.push({
                            id: notification.id,
                            emailSentAt: notification.email_sent_at ? moment(notification.email_sent_at).format("YYYY/MM/DD HH:mm") : "",
                            username: notification.user.name,
                            email: notification.user.email,
                            time: moment(notification.time).format("YYYY/MM/DD HH:mm"),
                            detail: formattedDetail,
							value: notification.value,
							unit: notification.report.unit,
							scope: notification.scope,
							report_id: notification.report.id,
							report_name:notification.report.name_jp,
							is_seen:notification.is_seen,

                        })
                    })
				}else if(notifType == "2"){
					response.data.map((notification) => {
                        csv_data.push({
                            id: notification.id,
                            emailSentAt: notification.email_sent_at ? moment(notification.email_sent_at).format("YYYY/MM/DD HH:mm") : "",
                            username: notification.user.name,
                            email: notification.user.email,
                            lastSyncTime: moment(notification.time).format("YYYY/MM/DD HH:mm"),
							is_seen:notification.is_seen,
                        })
                    })
				}
				setFilename(data.workspace_name + '_'+ notifType + '_notifications.csv')
				setCsvData(csv_data);
				
				csvLink.current.link.click()
				setCsvLoading(false);
			},
			handleError: (error: any): void => {
				console.log(error);
				setCsvLoading(false);
			}
		})
	}







	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item xs={12}>
					<Box sx={{ mx: 2, my: 3 }}>
						<Typography variant='h6' display='inline'>
							{"ID: " + data.workspace_name}<Box sx={{ m: 1 }} />
							{editText ?
								<Tooltip title={t('data.data_modify')}>
									<LoadingButton onClick={handleEdit}>
										<BorderColor sx={{ fontSize: 35 }} />
									</LoadingButton>
								</Tooltip>
								:
								<Tooltip title={t('data.data_change')}>
									<LoadingButton
										loading={loading}
										type="submit"
										onClick={handleChange}>
										<CheckIcon sx={{ fontSize: 35 }} />
									</LoadingButton>
								</Tooltip>}


						</Typography>

					</Box>
				</Grid>

				<Grid item xs={12}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell sx={{ width: 270 }}>
									{t('table.name')}
								</TableCell>
								<TableCell align='left'>
								
										{editText ? name :
											<form >
												<TextField
													multiline
													defaultValue={name}
													sx={{ m: 1, width: '80ch' }}
													id="name"
													name="name"
													onChange={changeValue}
												/>
											</form>}
								
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>

									{t('table.description')}
									
								</TableCell>

								<TableCell>
									{editText ? description :
										
											<form >
												<TextField
													multiline
													defaultValue={description}
													sx={{ m: 1, width: '80ch' }}
													id="description"
													name="description"
													onChange={changeValue}
												/>
											</form>
										
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>
									<b>{t('table.status')}</b>
								</TableCell>
								{editText ? <PublicRender /> :
									<>
										<TableCell>
											<Tooltip title={isPublic ? t('table.is_private') : t('table.is_public')}>
												<Switch checked={isPublic} onChange={changeValue} />
											</Tooltip>

										</TableCell>
									</>
								}
							</TableRow>

							{/* NOTIFICATION SETTINGS STATUS START */}
							<TableRow>
								<TableCell align="left">
									{t("notification_settings.notification_settings_status")}
								</TableCell>
								<TableCell align="left">
									{
										editText ? 
											(isAllowed ? t("notification_settings.status.unlocked") : t("notification_settings.status.locked")) :
											(
												<LockSwitch 
													checked={isAllowed}
													onChange={handleSwitchChange}
													label={
														isAllowed
															? t("notification_settings.is_allowed")
															: t("notification_settings.not_allowed")
													}
												/>
											)
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">
									{t("table.language")}
								</TableCell>
								<TableCell align="left">

										{editText ?(language=='jp' ? '日本語' : 'English'): (
										<FormControl fullWidth sx={{ minWidth: 120 }}>
											<InputLabel id="demo-simple-select-autowidth-label">{t('table.language')}</InputLabel>
											<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											
											value={language}
											label="Language"
											onChange={handleLanguageChange}
											>
												{languages.map((e) => (
													<MenuItem
													key={e}
													value={e}
													>
													{e=="jp"?'日本語':'English'}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										)
									}

								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">
								{t('report.csv_download_btn')}
								</TableCell>
								<TableCell align="left">
				<form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<TextField
						id="datetime-local"
						label={t('report.starting_date')}
						type="datetime-local"
						onChange={(event) => setStartDate(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mr: 1 }}
					/>
					<TextField
						id="datetime-local"
						label={t('report.end_date')}
						type="datetime-local"
						onChange={(event) => setEndDate(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mx: 1 }}
					/>
					<FormControl sx={{ textAlign: 'left', mx: 1 }}>
						<InputLabel id='interval-label'>{t('table.type')}</InputLabel>
						<Select
							label={t('table.type')}
							id='interval'
							value={notifType}
							labelId='interval-label'
							onChange={(event) => setNotifType(event.target.value)}
						>
							
							<MenuItem value='1'>{t('notifications.measurement_value')}</MenuItem>
							<MenuItem value='2'>{t('notifications.synchronization')}</MenuItem>
							
						</Select>
					</FormControl>


					<LoadingButton loading={csvLoading} variant='outlined' color={'primary'} size="large" type='submit'>
						<DownloadSharpIcon />
					</LoadingButton>
					<CSVLink
						filename={filename}
						data={csvData}
						ref={csvLink}
					>
					</CSVLink>
					
					
				</form>

								
								</TableCell>
							</TableRow>
							{/* NOTIFICATION SETTINGS STATUS END */}
							{editText ? null :
								
								<TableRow>

									   <Box component="td" marginLeft={50} /> 
										<TableCell  align='right'>
										
												<><DeleteFormDialog updateRender={updateRender} data={data.workspace_name} /></>
											
										</TableCell></TableRow>

							}

						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default withRouter(Manage);