import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import UniversalCookie from 'universal-cookie';
import { useTranslation } from "react-i18next"
import moment from "moment";

import {
    setStartDate,
    setInterval,
    // setNotificationCount,
    // decNotificationCount
} from "src/redux/staff-report/actions";

import api, { apiReturnRes } from 'src/utilities/axios';

import { Stack, Grid, Typography, Breadcrumbs, Link, Divider, IconButton, Button, TextField } from "@material-ui/core";
import { DeleteSharp, VisibilitySharp, Search } from '@material-ui/icons';
import Loader from 'src/components/loader'
import DataTable from "./components/data-table"
import { eventDragMutationMassager } from '@fullcalendar/core';

const options = [
    {
        id: "00005091",
        name: "Heart Rate"
    },
    {
        id: "00005092",
        name: "Skin Temperature"
    },
    {
        id: "00005093",
        name: "Blood Oxygen"
    },
    {
        id: "00005094",
        name: "Respiratory Rate"
    },
    {
        id: "00005095",
        name: "BP"
    },
    {
        id: "00005097",
        name: "Steps"
    },
    {
        id: "00005098",
        name: "ResQ Score"
    },
]

export default function Notifications() {
    const cookie = new UniversalCookie();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [vitalNotifications, setVitalNotifications] = useState([]);
    const [syncNotifications, setSyncNotifications] = useState([]);
    const [startTime, setStart] = React.useState<string>();
	const [endTime, setEnd] = React.useState<string>();
    
	
    const handleSubmit = (event) => {
        event.preventDefault();
        fetchData();

      };
    

    const fetchData = () => {
        setIsLoading(true);
        let startUnixDate = Date.parse(startTime);
        let endUnixDate = Date.parse(endTime);		

        let pathname = '/staff/' + cookie.get('workspace') + '/notifications'

        pathname += '?start_time=' + startUnixDate / 1000;
        pathname += '&end_time=' + endUnixDate / 1000;


        api({
            pathname: pathname,
            method: 'GET',
            handleResponse: (response: any): void => {
                const vitalNotificationsTemp = [];
                const syncNotificationsTemp = [];

                for (let i = 0; i < response.data.length; i++) {
                    switch (response.data[i].notification_type) {
                        case 1: {
                            vitalNotificationsTemp.push(response.data[i]);
                            break;
                        }
                        case 2: {
                            syncNotificationsTemp.push(response.data[i]);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }

                // dispatch(setNotificationCount(response.data.length));
                setVitalNotifications(vitalNotificationsTemp);
                setSyncNotifications(syncNotificationsTemp);
                setIsLoading(false);
            },
            handleError: (error: any): void => {
                console.log(error);
                setIsLoading(false);
            }
        })
    }

    useEffect(fetchData, []);

    const createVitalNavigate = (notification) => (e) => {
        dispatch(setInterval("hourly"))
        dispatch(setStartDate(moment(notification.time).toDate()))
        navigate(`/staff/reports/${notification.report.id}/${notification.user.uid}?name=${notification.user.name}&email=${notification.user.email}`)
    }

    const createVitalDelete = (id) => (e) => {
        setVitalNotifications([
            ...vitalNotifications.filter(n => n.id !== id)
        ]);
        // dispatch(decNotificationCount());

        api({ pathname: '/staff/' + cookie.get('workspace') + '/notifications/' + id, method: "DELETE" })
    }

    const deleteSelectedVital = (selectedIds) => (e) => {
        setVitalNotifications([
            ...vitalNotifications.filter(n => !selectedIds.includes(n.id))
        ]);

        Promise.all(
            selectedIds.map(
                (id) => {
                    return apiReturnRes({ pathname: '/staff/' + cookie.get('workspace') + '/notifications/' + id, method: "DELETE" })
                })
        )
        // .then((responses) => {
        //     console.log(responses);
        // });
    }

    const deleteSelectedSync = (selectedIds) => (e) => {
        setSyncNotifications([
            ...syncNotifications.filter(n => !selectedIds.includes(n.id))
        ]);

        Promise.all(
            selectedIds.map(
                (id) => {
                    return apiReturnRes({ pathname: '/staff/' + cookie.get('workspace') + '/notifications/' + id, method: "DELETE" })
                })
        )
        // .then((responses) => {
        //     console.log(responses);
        // });
    }

    const createSyncDelete = (id) => (e) => {
        setSyncNotifications([
            ...syncNotifications.filter(n => n.id !== id)
        ])
        // dispatch(decNotificationCount());

        api({ pathname: '/staff/' + cookie.get('workspace') + '/notifications/' + id, method: "DELETE" })
    }

    return (
        <Loader state={isLoading}>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                        {t('navigation.notifications')}
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} color='inherit' to='/staff'>
                            {t('navigation.dashboard')}
                        </Link>
                        <Typography color='text.primary'>
                            {t('navigation.notifications')}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<TextField
						id="datetime-local"
						label={t('report.starting_date')}
						type="datetime-local"
                        defaultValue={startTime}
						onChange={(event) => setStart(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mr: 1 }}
					/>
					<TextField
						id="datetime-local"
						label={t('report.end_date')}
						type="datetime-local"
                        defaultValue={endTime}
						onChange={(event) => setEnd(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mx: 1 }}
					/>

                <Button variant="outlined" endIcon={<Search />} type='submit'>
                        Search
                </Button>
					
					
				</form>
                <DataTable
                    toolbar={{
                        title: t('notifications.synchronization'),
                        actions: (selectedIds) => (
                            <IconButton onClick={deleteSelectedSync(selectedIds)}>
                                <DeleteSharp />
                            </IconButton>
                        )
                    }}
                    defaultSortBy='emailSentAt'
                    data={syncNotifications.map((notification) => {
                        return {
                            id: notification.id,
                            emailSentAt: notification.email_sent_at ? moment(notification.email_sent_at).format("YYYY/MM/DD HH:mm") : "",
                            username: notification.user.name,
                            email: notification.user.email,
                            lastSyncTime: moment(notification.time).format("YYYY/MM/DD HH:mm"),
                            delete: (
                                <IconButton onClick={createSyncDelete(notification.id)}>
                                    <DeleteSharp />
                                </IconButton>
                            ),
                        }
                    })}
                    header={[
                        {
                            key: "emailSentAt",
                            i18nKey: "notifications.table.email_sent_at",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "username",
                            i18nKey: "notifications.table.username",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "email",
                            i18nKey: "notifications.table.account",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "lastSyncTime",
                            i18nKey: "notifications.table.last_sync_time",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "delete",
                            i18nKey: "notifications.table.delete",
                            align: "center",
                            sortable: false,
                        },
                    ]}
                />

                <DataTable
                    defaultSortBy="emailSentAt"
                    toolbar={{
                        title: t('notifications.measurement_value'),
                        actions: (selectedIds) => (
                            <IconButton onClick={deleteSelectedVital(selectedIds)}>
                                <DeleteSharp />
                            </IconButton>
                        )
                    }}
                    data={vitalNotifications.map((notification) => {
                        const formattedDetail = `${notification.report.name_jp}の値が${notification.value}${notification.report.unit}です。貴方が設定された${notification.scope}レッドゾーンになっています。`;

                        return {
                            id: notification.id,
                            emailSentAt: notification.email_sent_at ? moment(notification.email_sent_at).format("YYYY/MM/DD HH:mm") : "",
                            username: notification.user.name,
                            email: notification.user.email,
                            time: moment(notification.time).format("YYYY/MM/DD HH:mm"),
                            detail: formattedDetail,
                            actions: (
                                <Stack spacing={1} direction="row">
                                    <IconButton onClick={createVitalDelete(notification.id)}>
                                        <DeleteSharp />
                                    </IconButton>
                                    <IconButton onClick={createVitalNavigate(notification)}>
                                        <VisibilitySharp />
                                    </IconButton>
                                </Stack>
                            ),
                        }
                    })}
                    header={[
                        {
                            key: "emailSentAt",
                            i18nKey: "notifications.table.email_sent_at",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "username",
                            i18nKey: "notifications.table.username",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "email",
                            i18nKey: "notifications.table.account",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "time",
                            i18nKey: "notifications.table.time",
                            align: "left",
                            sortable: true,
                        },
                        {
                            key: "detail",
                            i18nKey: "notifications.table.detail",
                            align: "left",
                            sortable: false,
                        },
                        {
                            key: "actions",
                            i18nKey: "notifications.table.actions",
                            align: "center",
                            sortable: false,
                        },
                    ]}
                />
            </Stack>
        </Loader>
    )
}
