import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import api from 'src/utilities/axios';
import UniversalCookie from 'universal-cookie';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import { stableSort, getComparator } from 'src/utilities/data-table';

import FormControl from '@material-ui/core/FormControl';
import Select ,{ SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


function DataTable(props: any) {
	const { data, deleteDialog, updateRender } = props;
	const { t } = useTranslation();

	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const cookie = new UniversalCookie();
	const languages: any = ['jp', 'en']
	const headCells: any = [
		{ id: 'name', align: 'left', disablePadding: false, label: t('table.name') },
		{ id: 'email', align: 'left', disablePadding: false, label: t('table.email') },
		{ id: 'language', align: 'right', disablePadding: false, label: t('table.language') },
		{ id: 'actions', align: 'right', disablePadding: false, label: t('table.actions') },
	];
	
	function handleLanguageChange(data: any, event: SelectChangeEvent): void {
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/change_language',
			method: 'POST',
			data: {
				email: data.email,
				language: event.target.value,
			},
			handleResponse: (): void => {
				updateRender();
			},
			handleError: (): void => {
				updateRender();
			}
		})
	}

	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleDeleteRequest(data: any): void {
		deleteDialog.setState(true);
		deleteDialog.setData(data);
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('table.staffs_table')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((item: any, index: number) => (
										<TableRow hover role='checkbox' tabIndex={-1} key={index + 1}>
											<TableCell align='left'>{item.name}</TableCell>
											<TableCell align='left'>{item.email}</TableCell>
											<TableCell align='right'>
												{/* <Tooltip title={t('table.language')}> */}
												<FormControl sx={{ minWidth: 60 }} variant="standard">
													{/* <InputLabel id="demo-simple-select-autowidth-label">{t('table.language')}</InputLabel> */}
													<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={item.language}
													// sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
													label="Language"
													disableUnderline
													onChange={(e) => handleLanguageChange(item, e)}
													>
														{languages.map((e) => (
															<MenuItem
															key={e}
															value={e}
															>
															{e=="jp"?'日本語':'English'}
															</MenuItem>
														))}
													</Select>
												</FormControl>
												{/* </Tooltip> */}
											</TableCell>
											<TableCell align='right'>
												<Tooltip title={t('table.delete')}>
													<IconButton onClick={() => handleDeleteRequest(item)}>
														<DeleteSharpIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Card>
	)
}

export default DataTable;